import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { useScrollToTop } from 'hooks';
import { Home, Movies, NotFound } from 'pages';

const GlobalStyle = createGlobalStyle`
  :root{
    --primary-500: #0EA5E9;
    --primary-600: #0284C7;
    --primary-700: #0369A1;
  }

  body {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin: 0;
    box-sizing: border-box;
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  a {
    color: currentColor;
    text-decoration: none;
  }
`;

function ScrollToTop({ children = null }) {
  useScrollToTop();
  return children;
}

export default function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/:type(movies|shows)/:id?">
              <Movies />
            </Route>
            <Route path="/404">
              <NotFound />
            </Route>
            <Redirect to="/404" />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
}
