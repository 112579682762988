import { Link } from "react-router-dom";
import { FilmIcon } from "@heroicons/react/outline";
import styled from "styled-components";
import mixins from "mixins";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--primary-600);
  font-weight: 500;
  letter-spacing: 0.05em;
  ${mixins.spaceX("0.25rem")}
  & > svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
`;

const Logo = () => (
  <StyledLink to="/">
    <FilmIcon />
    <span>ReactFlix</span>
  </StyledLink>
);

export default Logo;
