import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import mixins from 'mixins';
import { Container, Logo } from 'components';

const StyledHeader = styled.header`
  border-bottom: 1px solid #d4d4d8;
  padding: 1rem;
  ${mixins.sm`
    padding: 1rem 1.5rem;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mixins.spaceX()}
  ${mixins.sm`
    justify-content: flex-start;
    ${mixins.spaceX('1.5rem')}
  `}
`;

const Nav = styled.nav`
  display: flex;
  ${mixins.spaceX()}
  & > a:hover {
    color: var(--primary-600);
  }
`;

const StyledNavLink = styled(NavLink)`
  &:hover {
    color: var(--primary-600);
  }

  &.active {
    color: var(--primary-600);
    font-weight: 600;
  }
`;

const Header = () => (
  <StyledHeader>
    <Container>
      <Wrapper>
        <Logo />
        <Nav>
          <StyledNavLink to="/movies">Movies</StyledNavLink>
          <StyledNavLink to="/shows">TV shows</StyledNavLink>
        </Nav>
      </Wrapper>
    </Container>
  </StyledHeader>
);

export default Header;
