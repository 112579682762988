import styled from 'styled-components';
import { Link } from 'react-router-dom';
import mixins from 'mixins';
import { Layout } from 'layout';
import { Container } from 'components';

const Wrapper = styled.div`
  text-align: center;
  padding: 2rem 1rem;
  ${mixins.sm`
    padding: 2rem 1.5rem;
  `}

  h1 {
    margin: 2rem 0;
    ${mixins.text('4xl')}
  }
`;

const ButtonLink = styled(Link)`
  background-color: var(--primary-600);
  color: white;
  padding: 0.75rem 1.5rem;
  ${mixins.rounded('md')};
  ${mixins.text('lg')};

  &:hover {
    background-color: var(--primary-700);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(2, 132, 199, 0.5);
    transition: box-shadow cubic-bezier(0.4, 0, 0.2, 1) 150ms;
`;

const Home = () => (
  <Layout>
    <Wrapper>
      <Container>
        <h1>404 - Not found</h1>
        <ButtonLink to="/">Go back home</ButtonLink>
      </Container>
    </Wrapper>
  </Layout>
);

export default Home;
